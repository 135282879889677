/* ClearCartButton
 */

import React from "react"
import useShoppingCart from "../hooks/use-shopping-cart"
import AccentedButton from "./accented-button"

export default function ClearCartButton(props) {

  const cart = useShoppingCart()

  return (
    <AccentedButton
      onClick={cart.clear}
      data-qa={props["data-qa"] || "ClearCartButton"}
    >
      Clear Cart
    </AccentedButton>
  )
}
