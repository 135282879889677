/* Tools for local items
 *
 * localItem(data)
 *
 */

// Get streamlined data from local item node
exports.localItem = node => {
  const data = {
    id: node.id,
    name: node.name,
    description: node.description,
    keywords: node.keywords,
    images: node.images,
    image: node.images.length > 0 ? node.images[0] : null,
    price: node.price,
    currency: node.currency,
    path: node.fields.path,
    data: {},
  }

  // Data is an object forced into array form
  if (node.data.length > 0) {
    for (const [k, v] of node.data) {
      data.data[k] = v
    }
  }

  return data
}
