/* Products
 *
 *  Products component that takes in data
 *
 *  Props:
 *    items: an array of items
 *    purchase (defaults to global setting): Boolean for including purchase actions
 *      If using local products and selling via stripe, product IDs must match Stripe
 *    filter (optional): function taking in a product (map) and returning a Boolean
 *    sort (optional): compare function for sorting as described in
 *      https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
 *      Defaults to lexicographic ordering by name.
 *
 *  Examples:
 *    <Products />
 *    <Products purchase={true} />
 *    <Products
 *      purchase={true}
 *      filter={product => product.keywords.includes("entree")}
 *      sort={(p1, p2) => p1.price < p2.price ? 1 : -1}
 *    />
 */

import React from "react"
import { formatCurrencyString } from "../util/currency"
import useUserPreferences from "../hooks/use-user-preferences"

import DisplayItemList from "./display-item-list"

export default function Products(props) {

  const globalPurchaseSetting = useUserPreferences().purchase
  const purchase = props.purchase !== undefined ? props.purchase : globalPurchaseSetting

  const productDisplayData = product => new Map(Object.entries({
    link: `/items/${product.id}/`,
    primaryInfo: product.price >= 0 ?
      formatCurrencyString(product.price, product.currency) :
      undefined,
    description: <i>{product.description}</i>,
    image: product.images.length > 0 ?
      product.images[0].src || product.images[0] :
      undefined,
    details: new Map(Object.entries({
      itemId: product.id,
      detailsContent: <i>{product.description}</i>,
      // product data is required for the shopping cart
      product: product,
    })),
  }))

  let items = props.items || []
  items = items.filter(props.filter)
  items = items.sort(props.sort)
  let displayProductList = []
  for (const product of items) {
    displayProductList.push([product.name, productDisplayData(product)])
  }

  return (
    <DisplayItemList
      items={new Map(displayProductList)}
      purchase={purchase}
      data-qa={props["data-qa"] || "ProductList"}
    />
  )
}

Products.defaultProps = {
  filter: product => true,

  // Lexicographic ordering by name
  sort: (p1, p2) => p1.name.toLowerCase().localeCompare(p2.name.toLowerCase()),
}
