/* stripeCheckout
 *
 * Usage:
 *
 *   error = stripeCheckout(cart, siteUrl)
 *   if (error) { console.warn("Error: ", error); }
 *
 * See also https://www.gatsbyjs.com/tutorial/ecommerce-tutorial/
 */

import getStripe from "./stripe-js"

export default async function stripeCheckout(cart, itemData, siteUrl) {
  const stripe = await getStripe()

  let lineItems = []
  for (const itemId of cart.items()) {
    lineItems.push({
      price: itemData.find(item => item.id === itemId).priceId,
      quantity: cart.getQuantity(itemId),
    })
  }

  const { error } = await stripe.redirectToCheckout({
    mode: "payment",
    lineItems: lineItems,
    successUrl: `${siteUrl}/purchase-successful/`,
    cancelUrl: `${siteUrl}/cart/`,
  })

  return error
}
