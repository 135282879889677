/* CheckoutButton
 *
 * https://useshoppingcart.com/usage/redirectToCheckout()
 *
 *  Props:
 *    setStatus: passes the status back to the parent
 *    loading: whether or not the checkout is loading
 *    setLoading: sets whether or not the checkout is loading
 */

import React from "react"
import useShoppingCart from "../hooks/use-shopping-cart"
import stripeCheckout from "../util/stripe-checkout"
import useSiteMetadata from "../hooks/use-site-metadata"
import useStripeItems from "../hooks/use-stripe-items"
import AccentedButton from "./accented-button"

export default function CheckoutButton(props) {

  const cart = useShoppingCart()
  const itemData = useStripeItems()
  const siteUrl = useSiteMetadata().siteUrl

  async function handleClick(e) {
    e.preventDefault()
    props.setLoading(true)
    if (cart.getQuantity() > 0) {
      const error = await stripeCheckout(cart, itemData, siteUrl)
      if (error) {
        props.setLoading(false)
        props.setStatus("redirect-error")
      }
    } else {
      props.setStatus("empty-cart")
    }
  }

  return (
    <AccentedButton
      disabled={props.loading}
      onClick={handleClick}
      data-qa={props["data-qa"] || "CheckoutButton"}
    >
      Checkout
    </AccentedButton>
  )
}
