/* formatCurrenctString
 *    Adapted from use-shopping-cart due to import problems
 *    https://github.com/dayhaysoos/use-shopping-cart/blob/master/use-shopping-cart/src/util.js
 */

export const isClient = typeof window === 'object'

export const formatCurrencyString = (
  value,
  currency = 'USD',
  language = isClient ? navigator.language : 'en-US'
) => {
  value = parseInt(value)
  const numberFormat = new Intl.NumberFormat(language, {
    style: 'currency',
    currency: currency,
    currencyDisplay: 'symbol'
  })
  const parts = numberFormat.formatToParts(value)
  let zeroDecimalCurrency = true

  for (const part of parts) {
    if (part.type === 'decimal') {
      zeroDecimalCurrency = false
      break
    }
  }

  value = zeroDecimalCurrency ? value : value / 100
  return numberFormat.format(value.toFixed(2))
}
