/* CartTotals
 */

import React from "react"
import styled from "styled-components"
import useIsClient from "../util/rehydration"
import CashRegister from "../util/cash-register"
import useShoppingCart from "../hooks/use-shopping-cart"
import useUserPreferences from "../hooks/use-user-preferences"
import useLocalItems from "../hooks/use-local-items"
import useStripeItems from "../hooks/use-stripe-items"

const SubtotalList = styled.div`
  margin-bottom: 24px;
  margin-left: 12px;
`

const SubtotalItem = styled.div`
  display: grid;
  grid-template-columns: 5em auto;
  grid-column-gap: 24px;
`

const ItemText = styled.div`
  color: var(--lightTextColor);
`

const Breakline = styled.hr`
  border: none;
  border-top: 3px double #333;
  color: #333;
  width: 200px;
  overflow: visible;
  text-align: left;
  margin-left: 0;
  height: 5px;
`

export default function CartTotals(props) {
  // For addressing rehydration issue
  // https://blog.logrocket.com/fixing-gatsbys-rehydration-issue/
  const { isClient, key } = useIsClient()
  const cart = useShoppingCart()
  const userPreferences = useUserPreferences()
  const itemData = useLocalItems().concat(useStripeItems())
  const cashRegister = new CashRegister(
    cart,
    itemData,
    userPreferences.currency,
    userPreferences.language,
    null,
  )

  const itemsJSX = cart.items().map( item => {
    const itemName = itemData.find(data => data.id === item).name
    return (
      <SubtotalItem
        key={item}
        data-qa={"CartSubtotalItem"}
      >
        <div data-qa={"CartSubtotalItemAmount"}>
          {cashRegister.getFormattedSubtotal(item)}
        </div>
        <ItemText data-qa={"CartSubtotalItemName"}>
          {itemName}
        </ItemText>
      </SubtotalItem>
    )
  })

  if ( !isClient ) return null
  return (
    <SubtotalList
      key={key}
      data-qa={"CartSubtotals"}
    >
      {itemsJSX}
      <Breakline />
      <SubtotalItem data-qa={"CartSubtotalTotal"}>
        <div data-qa={"CartSubtotalTotalAmount"}>
          <strong>{cashRegister.getFormattedSubtotal()}</strong>
        </div>
        <div data-qa={"CartSubtotalTotalName"}>
          <strong>Subtotal</strong>
        </div>
      </SubtotalItem>
    </SubtotalList>
  )
}
