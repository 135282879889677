/* useLocalItems
 *
 * Get locally defined items
 *
 * Usage:
 *
 *   import useLocalItems from "../hooks/use-local-items"
 *   const itemData = useLocalItems()
 *   // itemData looks like [
 *   //   {id: "foo", name: "bar", ...},
 *   //   {id: "foo2", name: "bar2", ...},
 *   //   ...
 *   // ]
 */

import { useStaticQuery, graphql } from "gatsby"
import { localItem } from "../util/local-items"

export default function useLocalItems() {
  const queryData = useStaticQuery(localItemsQuery)
  const items = []
  for (const node of queryData.allItemsYaml.nodes) {
    items.push(localItem(node))
  }
  return items
}

const localItemsQuery = graphql`
  query {
    allItemsYaml {
      nodes {
        id
        name
        description
        keywords
        images
        price
        currency
        data
        fields {
          path
        }
      }
    }
  }
`
