/* StripeProducts
 *
 *  Fetches data and passes it to the products component
 *
 *  Props:
 *    purchase (defaults to global setting): Boolean for including purchase actions
 *      If using local products and selling via stripe, product IDs must match Stripe
 *    filter (optional): function taking in a product (map) and returning a Boolean
 *    sort (optional): compare function for sorting as described in
 *      https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
 *      Defaults to lexicographic ordering by name.
 *
 *  Examples:
 *    <StripeProducts />
 *    <StripeProducts
 *      purchase={true}
 *      filter={product => product.keywords.includes("entree")}
 *      sort={(p1, p2) => p1.price < p2.price ? 1 : -1}
 *    />
 */

import React from "react"
import useStripeItems from "../hooks/use-stripe-items"

import Products from "./products"

export default function StripeProducts(props) {

  const items = useStripeItems()

  return (
    <Products
      purchase={props.purchase}
      filter={props.filter}
      sort={props.sort}
      items={items}
      data-qa={props["data-qa"] || "StripeProducts"}
    />
  )
}
