/* CashRegister
 */

import { formatCurrencyString } from "./currency"

const DEFAULT_LANGUAGE = "en-US"
const DEFAULT_CURRENCY = "USD"

const _getLanguage = () => {
  let language = DEFAULT_LANGUAGE
  if (navigator) {
    language = navigator.languages ? navigator.languages[0] :
      navigator.language || navigator.userLanguage || DEFAULT_LANGUAGE
  }
  return language
}

export default class CashRegister {
  constructor(
    cart,
    itemData,
    currency = DEFAULT_CURRENCY,
    language = null,
    checkout = null,
  ) {
    this.cart = cart
    this.items = {}
    for (const item of itemData) {
      this.items[item.id] = item
    }
    this.langauge = language || _getLanguage()
    this.currency = currency
  }

  getSubtotal(item = null) {
    let subtotal = 0
    if (!!item) {
      subtotal = this.items[item].price * this.cart.getQuantity(item)
    } else {
      const sumReducer = (partialSum, nextItem) => partialSum + this.getSubtotal(nextItem)
      subtotal = this.cart.items().reduce(sumReducer, 0)
    }
    return subtotal
  }

  getFormattedSubtotal(item = null) {
    return formatCurrencyString(
      this.getSubtotal(item),
      this.currency,
      this.language,
    )
  }

  checkout() {
    return
  }

}
